import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Underbanked Financial Services Forum has always been a favorite of mine so it was an extremely rewarding day when we were asked to present PayPerks as part of a panel on the Next Wave of Underbanked Innovators. To boot, we were good company – immediately following our fellow Anthemis-backed friend Scott Saunders of Payoff and before Jeff Stewart of Lenddo.`}</p>
    <p>{`As Arjan Schütte, a long-time PayPerks advisor and General Partner at Core Innovation Capital (the venture arm of CFSI), introduced PayPerks, it struck me that this was a full circle moment. Four years ago, I didn’t know a thing about the underbanked and it was largely through CFSI’s research and conferences and Arjan’s guidance that I got up to speed. Then, the very hand that taught us gave us a forum to teach others about what we’re doing and why. Following the event, we got some very nice notes from aspiring entrepreneurs who said our story has encouraged them to focus their efforts on innovating for the financially underserved market.`}</p>
    <p>{`A virtuous cycle indeed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      